import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_GET = process.env.VUE_APP_API_URL_GET;

export default {
      state: {
            winners: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null                  
            },            
      },

      getters: {
            loading: state =>
            {
                  return state.winners.loading;
            },
            winners: state =>
            {
                  return state.winners.data;
            }
              
      },

      mutations: {
            setWinners(state, data)
            {
                  state.winners.data = data;
            },
            setWinnersLoading(state, status)
            {
                  state.winners.loading = status;
            },
            setWinnersError(state, status)
            {
                  state.winners.error = status;
            },
            setWinnersErrorMessage(state, message)
            {
                  state.winners.errorMessage = message;
            }           
      },

      actions: {
            async getWinners({ commit })
            {
                  commit("setWinnersLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'winners';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setWinnersLoading", false);
                        commit("setWinnersError", true);
                        commit("setWinnersErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setWinners", response.data.data);                        
                        commit("setWinnersLoading", false);
                        commit("setWinnersError", false);
                        commit("setWinnersErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }                  
            }          
      },

      namespaced: true
};
