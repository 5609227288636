import Vue from 'vue';
import Vuex from 'vuex';

//import websocket from './websocket';

import { auth } from './account/auth.module';
import register  from './account/register';
import forgotpassword  from './account/forgotpassword';
// import admin from './admin';
import tournamentsg from './get/tournaments';
import tournamentsp from './post/tournaments';
import games from './get/games';
import tipsg from './get/tips';
import tipsp from './post/tips';
import teams from './get/teams';
import winners from './get/winners';
import stats from './get/stats';
import groups from './get/groups';
import dashboard from './get/dashboard';
import gbookg from './get/gbook';
import gbookp from './post/gbook';
import profileg from './get/profile';
import profilep from './post/profile';

import administratorget from "./administrator/get";
import administratorpost from "./administrator/post";

import adminget from "./admin/get";
import adminpost from "./admin/post";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    //websocket,
    auth,
    register,
    forgotpassword,
    games,
    teams,
    dashboard,
    gbookg,
    gbookp,
    tipsg,
    tipsp,
    stats,
    winners,
    profileg,
    profilep,    
    groups,    
    tournamentsg,
    tournamentsp,
    administratorget,
    administratorpost,
    adminget,
    adminpost,
  }
});

