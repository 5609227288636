import store from "../store";

export default {

    decodeToken(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    },
    findById(list, key, id)
    {
        for(var i = 0; i < list.length; i++)
        {
            if(list[i][key] == id)
                return list[i];
        }
        return false;
    },
    fixPlacements(stats, statskey, placekey) {
        var placement = 0;
        var currentpoints = false;
        for (var i = 0; i < stats.length; i++) {
            var row = stats[i];
            if (currentpoints != row[statskey]) {
                placement = (i + 1);
            }
            currentpoints = row[statskey];
            row[placekey] = placement;
        }

        return stats;
    },
    hasTournament() {
        var token = this.getToken();
        if (!token)
            return false;

        var tokenjson = this.decodeToken(token);
        if (tokenjson.tournament && tokenjson.tournament > 0)
            return true;
        return false;
    },
    tokenTournamentAccess(){
        if(this.hasTournament){
            var token = this.decodeToken(this.getToken());
            return token.access;
        }
        return false;
    },
    getToken() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user)
            return user.accessToken;
        else
            return false;
    },
    getTeam(id) {
        var teams = store.getters["teams/teams"];
        if (teams[id])
            return teams[id];
        return false;
    },
    sort(list, key, descending) {
        var sorted = list.sort(function (a, b) {
            if (a[key] < b[key]) //sort string ascending
                return -1;
            if (a[key] > b[key])
                return 1;
            return 0; //default return value (no sorting)            
        });

        if (descending)
            return sorted.reverse();
        return sorted;
    },
    sortint(list, key, descending) {
        var sorted = list.sort(function (a, b) {
            if (parseInt(a[key]) < parseInt(b[key])) //sort string ascending
                return -1;
            if (parseInt(a[key]) > parseInt(b[key]))
                return 1;
            return 0; //default return value (no sorting)            
        });

        if (descending)
            return sorted.reverse();
        return sorted;
    },
    sortm(list, keys) {
        var sorted = list.sort(function (a, b) {
            for (var i = 0; i < keys.length; i++) {
                var key = keys[i]
                var val_a = a[key.key];
                var val_b = b[key.key];
                if (key.float) { // om ska vara siffror
                    val_a = parseFloat(a[key.key]);
                    val_b = parseFloat(b[key.key]);
                }

                if (val_a < val_b) //sort string ascending
                    return key.desc ? 1 : -1;
                if (val_a > val_b)
                    return key.desc ? -1 : 1;
                // om lika så gå efter nästa key
                // om sista nyckeln så helt lika
                if (i == keys.length - 1)
                    return 0; //default return value (no sorting)

            }
        });

        return sorted;
    },
    groupGamesByKey(games, key) {
        var newgames = [];
        var ggames = [];
        var currVal = false;
        for (var i = 0; i < games.length; i++) {
            var g = games[i];
            if (currVal && currVal != g[key]) {
                newgames.push(ggames);
                ggames = [];
            }

            ggames.push(g);
            currVal = g[key];
        }

        newgames.push(ggames);

        return newgames;
    },
    position(el) {
        var _x = 0;
        var _y = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    },
    scroll(element, options) {
        var topPos = this.position(element).top;
        var wh = window.outerHeight;
        var bh = this.bodyHeight();
        if (topPos > 0) {
            if (options && options.center) {
                if ((bh - (wh/2)) > topPos) {
                    topPos -= (wh / 2);
                }
            }
            if (options && options.offset) {
                topPos -= options.offset;
            }

            window.scrollTo({
                left: 0,
                top: topPos,
                behavior: 'smooth'
            });
        }
    },
    bodyHeight() {
        var body = document.body,
            html = document.documentElement;

        return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    },
    clone(item){
        var json = JSON.stringify(item);
        return JSON.parse(json);
    },
    empty(obj){
        return JSON.stringify(obj) === JSON.stringify({});
    }
};
