export default {
      svg(svgImg)
      {
            const url = require("@/assets/svg/" + svgImg);
            return url;
      },
      userAvatar(id, imgversion)
      {
            if (!imgversion)
                  imgversion = 0;
            let url = '/images/avatars/user_' + id + '.jpg?v=' + imgversion;
            return url;
      },
      tournamentImage(uuid, imgversion)
      {
            if (!imgversion)
                  imgversion = 0;
            let url = '/images/logos/' + uuid + '.jpg?v=' + imgversion;
            return url;
      },
      teamFlag(flagname)
      {
            let url = '/images/flags/' + flagname;
            return url;
      },
      swishimage(name)
      {
            const url = require("@/assets/images/swish/" + name);
            return url;
      }
};
