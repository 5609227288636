import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_GET = process.env.VUE_APP_API_URL_GET;

export default {
      state: {
            groups: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null                  
            }
      },

      getters: {
            groupsloading: state =>
            {
                  return state.groups.loading;
            },
            groups: state =>
            {
                  return state.groups.data;
            },           
            tournamentloading: state =>
            {
                  return state.tournament.loading;
            },
            tournament: state =>
            {
                  return state.tournament.data;
            },
            usergroupsloading: state => {
                  return state.usergroups.loading;
            },
            usergroups: state => {
                  return state.usergroups.data;
            }      
      },

      mutations: {
            setGroups(state, data)
            {
                  state.groups.data = data;
            },
            setGroupsLoading(state, status)
            {
                  state.groups.loading = status;
            },            
            setGroupsError(state, status)
            {
                  state.groups.error = status;
            },
            setGroupsErrorMessage(state, message)
            {
                  state.groups.errorMessage = message;
            },            
            
      },

      actions: {
            async getGroups({ commit })
            {
                  commit("setGroupsLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'groups';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setGroupsLoading", false);
                        commit("setGroupsError", true);
                        commit("setGroupsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setGroups", response.data.data);                        
                        commit("setGroupsLoading", false);
                        commit("setGroupsError", false);
                        commit("setGroupsErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }                  
            }            
      },

      namespaced: true
};
