import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_POST = process.env.VUE_APP_API_URL_POST;

export default {
      state: {
            selectTournament:{
                  loading: false
            },
            joinTournament: {
                  loading: false
            }
      },

      getters: {
            selectloading: state =>
            {
                  return state.selectTournament.loading;
            },
            joinloading: state =>
            {
                  return state.joinTournament.loading;
            },
      },

      mutations: {            
            setSelectTournamentLoading(state, status)
            {
                  state.selectTournament.loading = status;
            },            
            
            setJoinTournamentLoading(state, status)
            {
                  state.joinTournament.loading = status;
            },            
      },

      actions: {
            async selectTournament({ commit }, id)
            {
                  commit("setSelectTournamentLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_POST + 'user-select-tournament';
                        response = await axios.post(url, { id: id}, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setSelectTournamentLoading", false);
                        // commit("setTournamentsError", true);
                        // commit("setTournamentsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {                        
                        commit("setSelectTournamentLoading", false);
                        if (response.data.token) { // sätt ny token
                              var user = JSON.parse(localStorage.getItem('user'));
                              user.accessToken = response.data.token;
                              localStorage.setItem('user', JSON.stringify(user));                              
                            }
                        // commit("setTournaments", response.data.data);
                        // commit("setTournamentsError", false);
                        // commit("setTournamentsErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }                  
            },  
            
            async joinTournament({ commit }, id)
            {
                  commit("setJoinTournamentLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_POST + 'user-join-tournament';
                        response = await axios.post(url, { id: id}, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setJoinTournamentLoading", false);
                        // commit("setTournamentsError", true);
                        // commit("setTournamentsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {                        
                        commit("setJoinTournamentLoading", false);
                        if (response.data.token) { // sätt ny token
                              var user = JSON.parse(localStorage.getItem('user'));
                              user.accessToken = response.data.token;
                              localStorage.setItem('user', JSON.stringify(user));                              
                            }
                        // commit("setTournaments", response.data.data);
                        // commit("setTournamentsError", false);
                        // commit("setTournamentsErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }                  
            },  

            // async getTournament({ commit })
            // {
            //       commit("setTournamentsLoading", true);

            //       let response;
            //       try
            //       {
            //             let url = API_URL_GET + 'tournaments';
            //             response = await axios.get(url, { headers: authHeader() });
            //       }
            //       catch (error)
            //       {
            //             commit("setTournamentsLoading", false);
            //             commit("setTournamentsError", true);
            //             commit("setTournamentsErrorMessage", error.message);
            //             return Promise.reject(error);
            //       }
            //       if (response)
            //       {
            //             commit("setTournaments", response.data.data);                        
            //             commit("setTournamentsLoading", false);
            //             commit("setTournamentsError", false);
            //             commit("setTournamentsErrorMessage", "");
            //             return Promise.resolve(response.data.data);
            //       }                  
            // }, 

            // async getUserTournaments({ commit })
            // {
            //       commit("setUserTournamentsLoading", true);

            //       let response;
            //       try
            //       {
            //             let url = API_URL_GET + 'user-tournaments';
            //             response = await axios.get(url, { headers: authHeader() });
            //       }
            //       catch (error)
            //       {
            //             commit("setUserTournamentsLoading", false);
            //             commit("setUserTournamentsError", true);
            //             commit("setUserTournamentsErrorMessage", error.message);
            //             return Promise.reject(error);
            //       }
            //       if (response)
            //       {
            //             commit("setUserTournaments", response.data.data);                        
            //             commit("setUserTournamentsLoading", false);
            //             commit("setUserTournamentsError", false);
            //             commit("setUserTournamentsErrorMessage", "");
            //             return Promise.resolve(response.data.data);
            //       } 
            // }
      },

      namespaced: true
};
