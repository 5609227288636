import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_GET = process.env.VUE_APP_API_URL_GET;

export default {
      state: {
            questions: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            },
            myquestions: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            },
            games: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            },
            mygames: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            },
            answered: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            },
            avgloading: false
      },

      getters: {
            questionsloading: state =>
            {
                  return state.questions.loading;
            },
            questions: state =>
            {
                  return state.questions.data;
            },
            myquestionsloading: state =>
            {
                  return state.myquestions.loading;
            },
            myquestions: state =>
            {
                  return state.myquestions.data;
            },
            gamesloading: state =>
            {
                  return state.games.loading;
            },
            games: state =>
            {
                  return state.games.data;
            },
            mygamesloading: state =>
            {
                  return state.mygames.loading;
            },
            mygames: state =>
            {
                  return state.mygames.data;
            },
            answeredloading: state =>
            {
                  return state.answered.loading;
            },
            answered: state =>
            {
                  return state.answered.data;
            },            

      },

      mutations: {
            setAVGLoading(state, status){
                  state.avgloading = status;
            },
            setQuestions(state, data)
            {
                  state.questions.data = data;
            },
            setQuestionsLoading(state, status)
            {
                  state.questions.loading = status;
            },
            setQuestionsError(state, status)
            {
                  state.questions.error = status;
            },
            setQuestionsErrorMessage(state, message)
            {
                  state.questions.errorMessage = message;
            },
            setMyQuestions(state, data)
            {
                  state.myquestions.data = data;
            },
            setMyQuestionsLoading(state, status)
            {
                  state.myquestions.loading = status;
            },
            setMyQuestionsError(state, status)
            {
                  state.myquestions.error = status;
            },
            setMyQuestionsErrorMessage(state, message)
            {
                  state.myquestions.errorMessage = message;
            },
            setGames(state, data)
            {
                  state.games.data = data;
            },
            setGamesLoading(state, status)
            {
                  state.games.loading = status;
            },
            setGamesError(state, status)
            {
                  state.games.error = status;
            },
            setGamesErrorMessage(state, message)
            {
                  state.games.errorMessage = message;
            },
            setMyGames(state, data)
            {
                  state.mygames.data = data;
            },
            setMyGamesLoading(state, status)
            {
                  state.mygames.loading = status;
            },
            setMyGamesError(state, status)
            {
                  state.mygames.error = status;
            },
            setMyGamesErrorMessage(state, message)
            {
                  state.mygames.errorMessage = message;
            },
            setAnswered(state, data)
            {
                  state.answered.data = data;
            },
            setAnsweredLoading(state, status)
            {
                  state.answered.loading = status;
            },
            setAnsweredError(state, status)
            {
                  state.answered.error = status;
            },
            setAnsweredErrorMessage(state, message)
            {
                  state.answered.errorMessage = message;
            },
      },

      actions: {
            // hämtar allas för att visa
            async getViewQuestionsTips({ commit })
            {
                  commit("setQuestionsLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'view-questions-tips';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setQuestionsLoading", false);
                        commit("setQuestionsError", true);
                        commit("setQuestionsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setQuestions", response.data.data);
                        commit("setQuestionsLoading", false);
                        commit("setQuestionsError", false);
                        commit("setQuestionsErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },

            // hämtar för att tipsa egna frågor
            async getTipsQuestionsTips({ commit })
            {
                  commit("setMyQuestionsLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'tips-questions-tips';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setMyQuestionsLoading", false);
                        commit("setMyQuestionsError", true);
                        commit("setMyQuestionsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setMyQuestions", response.data.data);
                        commit("setMyQuestionsLoading", false);
                        commit("setMyQuestionsError", false);
                        commit("setMyQuestionsErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },

            // hämtar allas för att visa
            async getViewGamesTips({ commit })
            {
                  commit("setGamesLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'view-games-tips';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setGamesLoading", false);
                        commit("setGamesError", true);
                        commit("setGamesErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setGames", response.data.data);
                        commit("setGamesLoading", false);
                        commit("setGamesError", false);
                        commit("setGamesErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },

            // hämtar för att tipsa egna matcher
            async getTipsGamesTips({ commit })
            {
                  commit("setMyGamesLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'tips-games-tips';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setMyGamesLoading", false);
                        commit("setMyGamesError", true);
                        commit("setMyGamesErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setMyGames", response.data.data);
                        commit("setMyGamesLoading", false);
                        commit("setMyGamesError", false);
                        commit("setMyGamesErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },

            // hämtar för att tipsa egna matcher
            async getAnswered({ commit })
            {
                  commit("setAnsweredLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'tips-answered';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setAnsweredLoading", false);
                        commit("setAnsweredError", true);
                        commit("setAnsweredErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setAnswered", response.data.data);
                        commit("setAnsweredLoading", false);
                        commit("setAnsweredError", false);
                        commit("setAnsweredErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },

            // hämtar avg för en match
            async getTipsAVG({commit}, gameid)
            {              
                  commit("setAVGLoading", true);    
                  let response;
                  try
                  {
                        let url = API_URL_GET + 'tips-game-avg?id='+gameid;
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {                        
                        commit("setAVGLoading", false);    
                        return Promise.reject(error);
                  }
                  if (response)
                  {                        
                        commit("setAVGLoading", false);    
                        return Promise.resolve(response.data.data);
                  }
            },
      },

      namespaced: true
};
