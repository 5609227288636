import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_GET = process.env.VUE_APP_API_URL_GET;

export default {
      state: {
            teams: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null                  
            },            
      },

      getters: {
            teamsloading: state =>
            {
                  return state.teams.loading;
            },
            teams: state =>
            {
                  return state.teams.data;
            },           
              
      },

      mutations: {
            setTeams(state, data)
            {
                  state.teams.data = data;
            },
            setTeamsLoading(state, status)
            {
                  state.teams.loading = status;
            },            
            setTeamsError(state, status)
            {
                  state.teams.error = status;
            },
            setTeamsErrorMessage(state, message)
            {
                  state.teams.errorMessage = message;
            },            
            // setTournament(state, data)
            // {
            //       state.tournament.data = data;
            // },          
            // setTournamentLoading(state, status)
            // {
            //       state.tournament.loading = status;
            // },
            // setTournamentError(state, status)
            // {
            //       state.tournament.error = status;
            // },
            // setTournamentErrorMessage(state, message)
            // {
            //       state.tournament.errorMessage = message;
            // },
            // setUserTournaments(state, data)
            // {
            //       state.usertournaments.data = data;
            // },          
            // setUserTournamentsLoading(state, status)
            // {
            //       state.usertournaments.loading = status;
            // },
            // setUserTournamentsError(state, status)
            // {
            //       state.usertournaments.error = status;
            // },
            // setUserTournamentsErrorMessage(state, message)
            // {
            //       state.usertournaments.errorMessage = message;
            // }
      },

      actions: {
            async getTeams({ commit })
            {
                  commit("setTeamsLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'teams';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setTeamsLoading", false);
                        commit("setTeamsError", true);
                        commit("setTeamsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setTeams", response.data.data);                        
                        commit("setTeamsLoading", false);
                        commit("setTeamsError", false);
                        commit("setTeamsErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }                  
            }          
      },

      namespaced: true
};
