import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_ADMINISTRATOR= process.env.VUE_APP_API_URL_ADMINISTRATOR;

export default {
      state: {
            tournaments: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            },
            users: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            },
            logs: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: []
            }
      },

      getters: {
            tournamentsloading: state =>
            {
                  return state.tournaments.loading;
            },
            tournaments: state =>
            {
                  return state.tournaments.data;
            },
            usersloading: state =>
            {
                  return state.users.loading;
            }, 
            users: state =>
            {
                  return state.users.data;
            },
            logsloading: state =>
            {
                  return state.logs.loading;
            }, 
            logs: state =>
            {
                  return state.logs.data;
            },
      },

      mutations: {
            setTournaments(state, data)
            {
                  state.tournaments.data = data;
            },
            setTournamentsLoading(state, status)
            {
                  state.tournaments.loading = status;
            },
            setTournamentsError(state, status)
            {
                  state.tournaments.error = status;
            },
            setTournamentsErrorMessage(state, message)
            {
                  state.tournaments.errorMessage = message;
            },
            setUsers(state, data)
            {
                  state.users.data = data;
            },
            setUsersLoading(state, status)
            {
                  state.users.loading = status;
            },
            setUsersError(state, status)
            {
                  state.users.error = status;
            },
            setUsersErrorMessage(state, message)
            {
                  state.users.errorMessage = message;
            },
            setLogs(state, data)
            {
                  state.logs.data = state.logs.data.concat(data);
            },
            setLogsLoading(state, status)
            {
                  state.logs.loading = status;
            },
            setLogsError(state, status)
            {
                  state.logs.error = status;
            },
            setLogsErrorMessage(state, message)
            {
                  state.logs.errorMessage = message;
            },
      },

      actions: {
            async getTournaments({ commit })
            {
                  commit("setTournamentsLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_ADMINISTRATOR + 'tournaments-get';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setTournamentsLoading", false);
                        commit("setTournamentsError", true);
                        commit("setTournamentsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setTournaments", response.data.data);
                        commit("setTournamentsLoading", false);
                        commit("setTournamentsError", false);
                        commit("setTournamentsErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },

            async getUsers({ commit })
            {
                  commit("setUsersLoading", true);                  
                  let response;
                  try
                  {
                        let url = API_URL_ADMINISTRATOR + 'users-get';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setUsersLoading", false);
                        commit("setUsersError", true);
                        commit("setUsersErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setUsers", response.data.data);
                        commit("setUsersLoading", false);
                        commit("setUsersError", false);
                        commit("setUsersErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },

            async getLogs({ commit }, page)
            {
                  commit("setLogsLoading", true);                  
                  let response;
                  try
                  {
                        let url = API_URL_ADMINISTRATOR + 'logs-get?page=' + page;
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setLogsLoading", false);
                        commit("setLogsError", true);
                        commit("setLogsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setLogs", response.data.data);
                        commit("setLogsLoading", false);
                        commit("setLogsError", false);
                        commit("setLogsErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            }
      },

      namespaced: true
};
