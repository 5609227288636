import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_GET = process.env.VUE_APP_API_URL_GET;

export default {
      state: {
            entries: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null                  
            },
            readlist: {
                  loading: false
            },
            readcount: {
                  data: null,
                  loading: false
            }
      },

      getters: {
            entriesloading: state =>
            {
                  return state.entries.loading;
            },
            entries: state =>
            {
                  return state.entries.data;
            },           
            tournamentloading: state =>
            {
                  return state.tournament.loading;
            },
            tournament: state =>
            {
                  return state.tournament.data;
            },
            userentriesloading: state => {
                  return state.userentries.loading;
            },
            userentries: state => {
                  return state.userentries.data;
            },
            readcount: state => {
                  return state.readcount.data;
            },
            readcountloading: state => {
                  return state.readcount.loading;
            }
      },

      mutations: {
            setEntries(state, data)
            {
                  state.entries.data = data;
            },
            setEntriesLoading(state, status)
            {
                  state.entries.loading = status;
            },            
            setEntriesError(state, status)
            {
                  state.entries.error = status;
            },
            setEntriesErrorMessage(state, message)
            {
                  state.entries.errorMessage = message;
            },
            setReadListLoading(state, status)
            {
                  state.readlist.loading = status;
            },
            setReadCount(state, data){
                  state.readcount.data = data;
            },
            setReadCountLoading(state, status)
            {
                  state.readcount.loading = status;
            }
      },

      actions: {
            async getEntries({ commit })
            {
                  commit("setEntriesLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'gbook';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setEntriesLoading", false);
                        commit("setEntriesError", true);
                        commit("setEntriesErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setEntries", response.data.data);                        
                        commit("setEntriesLoading", false);
                        commit("setEntriesError", false);
                        commit("setEntriesErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }                  
            },
            
            async getReadList({commit}, id)
            {            
                  commit("setReadListLoading", true);    
                  let response;
                  try
                  {
                        let url = API_URL_GET + 'gbook-readlist?id=' + id;
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {                        
                        return Promise.reject(error);
                  }
                  if (response)
                  {                        
                        return Promise.resolve(response.data.data);
                  }                  
            },

            async getUserReadCount({commit})
            { 
                  let response;                  
                  commit("setReadCountLoading", true);    
                  try
                  {
                        let url = API_URL_GET + 'gbook-user-read-count';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {                        
                        return Promise.reject(error);
                  }
                  commit("setReadCountLoading", false);    
                  if (response)
                  {                        
                        commit("setReadCount", response.data.data);
                        return Promise.resolve(response.data.data);
                  }                  
            }
      },

      namespaced: true
};
