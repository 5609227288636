import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_ADMINISTRATOR= process.env.VUE_APP_API_URL_ADMINISTRATOR;

export default {
      state: {
            tournamentadd: {
                  error: false,
                  errors: [],
                  loading: false,
                  data: null
            }            
      },

      getters: {
            tournamentloading: state =>
            {
                  return state.tournamentadd.loading;
            },
            tournamenterror: state => {
                  return state.tournamentadd.error;
            },
            tournamenterrors: state => {
                  return state.tournamentadd.errors;
            },
            tournament: state =>
            {
                  return state.tournamentadd.data;
            },            
      },

      mutations: {
            setTournamentAddLoading(state, status)
            {
                  state.tournamentadd.loading = status;
            },
            setTournamentAdd(state, data)
            {
                  state.tournamentadd.data = data;
            },
            setTournamentAddError(state, status)
            {
                  state.tournamentadd.error = status;
            },
            setTournamentAddErrors(state, errors)
            {
                  state.tournamentadd.errors = errors;
            }            
      },

      actions: {
            async AddTournament({ commit }, data)
            {
                  commit("setTournamentAddLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_ADMINISTRATOR + 'tournament-post';
                        response = await axios.post(url, data, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setTournamentAddLoading", false);
                        commit("setTournamentAddError", true);
                        commit("setTournamentAddErrors", error.errors);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setTournamentAdd", response.data.data);
                        commit("setTournamentAddLoading", false);
                        commit("setTournamentAddError", response.data.status != "ok");
                        commit("setTournamentAddErrors", response.data.errors);
                        return Promise.resolve(response.data);
                  }
            },    
            
            async SendSMS({commit}, data)
            {                  
                  commit("setTournamentAddLoading", false);
                  let response;
                  try
                  {
                        let url = API_URL_ADMINISTRATOR + 'sms-post';
                        response = await axios.post(url, data, { headers: authHeader() });
                  }
                  catch (error)
                  {                        
                        return Promise.reject(error);
                  }
                  if (response)
                  {                        
                        return Promise.resolve(response.data);
                  }
            }
      },

      namespaced: true
};
