import images from "@/helpers/images";
import formatter from "@/helpers/formatter";
import time from "@/helpers/time";
import date from "@/helpers/date";
import url from "@/helpers/url";
import functions from "@/helpers/functions";


export default {
    install(Vue) {        
        Vue.prototype.$images = images;
        Vue.prototype.$formatter = formatter;
        Vue.prototype.$time = time;
        Vue.prototype.$date = date;        
        Vue.prototype.$url = url;        
        Vue.prototype.$functions = functions;        
    }
};