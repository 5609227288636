import Vue from 'vue';
import App from './App.vue';
import { router } from './router/router';
import store from './store';

import VeeValidate from 'vee-validate';
import Vuex from 'vuex';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false;

const config = {  
  fieldsBagName: 'fieldsvee', //Default is fields
  };
  
Vue.use(VeeValidate, config);

// timer
var vueAwesomeCountdown = require('vue-awesome-countdown').default;
Vue.use(vueAwesomeCountdown);

// vue-touch
import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

// vue-slider / tipsligan
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
Vue.component('VueSlider', VueSlider)

//vue-select
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

// SVG inline
import InlineSvg from 'vue-inline-svg';
Vue.component('inline-svg', InlineSvg);

import UserPopover from './components/miscellaneous/Userpopup.vue';
Vue.component('UserPopover', UserPopover);

// Axios
import { setupAxios } from "./plugins/axios";
setupAxios();

// vue-upload-component
const VueUploadComponent = require('vue-upload-component')
Vue.component('file-upload', VueUploadComponent)

// helpers
import plugins from "./helpers/";
Vue.use(plugins);

import InView from 'vue-inview';
Vue.use(InView);

// //import { Datetime } from 'vue-datetime';
// // You need a specific loader for CSS files
// //import 'vue-datetime/dist/vue-datetime.css'
// //Vue.component('datetime', Datetime);

// import { Settings } from 'luxon'
 
// Settings.defaultLocale = 'se';

 Vue.use(Vuex);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
