import axios from "axios";

const API_URL_POST = process.env.VUE_APP_API_URL_POST;

export default {
      state: {
            forgot: {
                  message: "",
                  status: false,
                  loading: false
            },
            validationCode:{
                  message: "",
                  status: false,
                  loading: false                                  
            },
            newpassword:
            {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null,
            }
      },

      getters: {
            registerloading: state =>
            {
                  return state.register.loading;
            },
            registersuccess: state =>
            {
                  return state.register.status;
            },
            registermessage: state =>
            {
                  return state.register.message;
            },
            validationCodeloading: state =>
            {
                  return state.validationCode.loading;
            },
            validationCodesuccess: state =>
            {
                  return state.validationCode.status;
            },
            validationCodemessage: state =>
            {
                  return state.validationCode.message;
            },
            lookup: state =>
            {
                  return state.lookup.data;
            }

      },

      mutations: {
            setForgotStatus(state, status)
            {
                  state.forgot.status = status;
            },
            setForgotLoading(state, status)
            {
                  state.forgot.loading = status;
            },
            setForgotMessage(state, message)
            {
                  state.forgot.message = message;
            },            
            setValidationCodeSuccess(state, data)
            {
                  state.validationCode.status = data;
            },
            setValidationCodeLoading(state, status)
            {
                  state.validationCode.loading = status;
            },
            setValidationCodeMessage(state, message)
            {
                  state.validationCode.message = message;
            },
            setNewPasswordLoading(state, data)
            {
                  state.newpassword.loading = data;
            },           
            setNewPasswordError(state, status)
            {
                  state.newpassword.error = status;
            },
            setNewPasswordErrorMessage(state, message)
            {
                  state.newpassword.errorMessage = message;
            },
      },

      actions: {
            async forgotpassword({ commit }, email)
            {
                  commit("setForgotLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_POST + 'forgotpassword';
                        response = await axios.post(url, {email: email});
                  }
                  catch (error)
                  {
                        commit("setForgotLoading", false);
                        commit("setForgotStatus", false);                        
                        commit("setForgotMessage", error.message);
                        return Promise.reject(error);
                  }
                  
                  if (response)
                  {                        
                        commit("setForgotLoading", false);
                        commit("setForgotStatus", true);                        
                        commit("setForgotMessage", "");
                        return Promise.resolve(response.data);
                  }
            },

            async validateForgotCode({ commit }, item)
            {
                  commit("setValidationCodeLoading", true);

                  let response;
                  try
                  {
                        var params = new URLSearchParams();
                        params.append('code', item.code);
                        params.append('email', item.email);
                        let url = API_URL_POST + 'validateforgotcode';
                        response = await axios.post(url, params);
                  }
                  catch (error)
                  {
                        commit("setValidationCodeSuccess", false);                        
                        commit("setValidationCodeMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {                        
                        commit("setValidationCodeSuccess", true);                        
                        commit("setValidationCodeMessage", "");
                        return Promise.resolve(response.data);
                  }
            },

            async setnewpassword({ commit }, item)
            {
                  commit("setNewPasswordLoading", true);

                  let response;
                  try
                  {
                        var params = new URLSearchParams();
                        params.append('password', item.password);
                        params.append('email', item.email);
                        params.append('code', item.code);
                        let url = API_URL_POST + 'forgotpasswordset';
                        response = await axios.post(url, params);
                  }
                  catch (error)
                  {              
                        commit("setNewPasswordLoading", false);                        
                        commit("setNewPasswordError", true);
                        commit("setNewPasswordErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {                                           
                        commit("setNewPasswordLoading", false);     
                        commit("setNewPasswordError", false);
                        commit("setNewPasswordErrorMessage", "");
                        return Promise.resolve(response.data);
                  }
            }
      },

      namespaced: true
};
