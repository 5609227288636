/* eslint-disable */
import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_POST = process.env.VUE_APP_API_URL_POST;

export default {
      state: {
            addentry:{
                  loading: false
            },
            joinTournament: {
                  loading: false
            }
      },

      getters: {
            addentryloading: state =>
            {
                  return state.addentry.loading;
            }              
      },

      mutations: {            
            setAddEntryLoading(state, status)
            {
                  state.addentry.loading = status;
            }
      },

      actions: {
            async addEntry({ commit }, entry)
            {
                  commit("setAddEntryLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_POST + 'gbook-add-entry';
                        response = await axios.post(url, entry, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setAddEntryLoading", false);
                        // commit("setTournamentsError", true);
                        // commit("setTournamentsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {                        
                        commit("setAddEntryLoading", false);                        
                        return Promise.resolve(response);
                  }                  
            },         

            async readEntry({}, entryid)
            {                                    
                  try
                  {                        
                        let url = API_URL_POST + 'gbook-entry-read';
                        await axios.post(url, { "entryid": entryid }, { headers: authHeader() });
                        return true;
                  }
                  catch (error)
                  {       
                        return false;
                  }
            },     

            // async getUserTournaments({ commit })
            // {
            //       commit("setUserTournamentsLoading", true);

            //       let response;
            //       try
            //       {
            //             let url = API_URL_GET + 'user-tournaments';
            //             response = await axios.get(url, { headers: authHeader() });
            //       }
            //       catch (error)
            //       {
            //             commit("setUserTournamentsLoading", false);
            //             commit("setUserTournamentsError", true);
            //             commit("setUserTournamentsErrorMessage", error.message);
            //             return Promise.reject(error);
            //       }
            //       if (response)
            //       {
            //             commit("setUserTournaments", response.data.data);                        
            //             commit("setUserTournamentsLoading", false);
            //             commit("setUserTournamentsError", false);
            //             commit("setUserTournamentsErrorMessage", "");
            //             return Promise.resolve(response.data.data);
            //       } 
            // }
      },

      namespaced: true
};
