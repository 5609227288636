import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_POST = process.env.VUE_APP_API_URL_POST;

export default {
      state: {
            uploadimage:{
                  loading: false
            },
            rotateimage: {
                  loading: false
            },      
            profile: {
                  loading: false,
            }      
      },

      getters: {
            imageloading: state =>
            {
                  return state.uploadimage.loading;
            },
            rotateloading: state =>
            {
                  return state.rotateimage.loading;
            },
            loading: state =>
            {
                  return state.profile.loading;
            }
      },

      mutations: {            
            setUploadImageLoading(state, status)
            {
                  state.uploadimage.loading = status;
            },
            setRotateImageLoading(state, status){
                  state.rotateimage.loading = status;
            },
            setProfileLoading(state, status)
            {
                  state.profile.loading = status;
            }
      },

      actions: {
            
            async SaveProfileField({ commit }, post)
            {
                  commit("setProfileLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_POST + 'profile-field-update';
                        response = await axios.post(url, post, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setProfileLoading", false);
                        // commit("setTournamentsError", true);
                        // commit("setTournamentsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {                        
                        commit("setProfileLoading", false);                        
                        return Promise.resolve(response);
                  }                  
            },         

            async RotateImage({ commit }, entry)
            {
                  commit("setRotateImageLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_POST + 'profile-image-rotate';
                        response = await axios.post(url, entry, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setRotateImageLoading", false);
                        // commit("setTournamentsError", true);
                        // commit("setTournamentsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {                        
                        commit("setRotateImageLoading", false);                        
                        return Promise.resolve(response);
                  }                  
            }, 

            async uploadFile({ commit }, file) {                  
                  let headers = authHeader();
                  headers["Content-Type"] = 'multipart/form-data';                  
                  //const config = { headers: { 'Content-Type': 'multipart/form-data' } };
                  const config = { headers: headers };
      
                  let fd = new FormData();
                  fd.append('file',file)

                  commit("setUploadImageLoading", true);
                  return axios.post(API_URL_POST + 'profile-image-upload',fd, config).then(function (response) {
                          commit("setUploadImageLoading", false);
                          if (response.data.Success) {
                              // commit("setError", false);
                          } else {
                              // commit("setError", true);
                          }
                          return Promise.resolve(response);
                      })
                      .catch(function (error) {
                          commit("setUploadImageLoading", false);
                          // commit("setError", true);
                          // commit("setErrorMessage", "Network error");
      
                          return Promise.reject(error);
                      });
              }
      },

      namespaced: true
};
