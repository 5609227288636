import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_GET = process.env.VUE_APP_API_URL_GET;

export default {
      state: {
            games: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            },
            teamgames: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            }
      },

      getters: {
            gamesloading: state =>
            {
                  return state.games.loading;
            },
            games: state =>
            {
                  return state.games.data;
            },
            teamgamesloading: state =>
            {
                  return state.teamgames.loading;
            }, 
            teamgames: state =>
            {
                  return state.teamgames.data;
            },
      },

      mutations: {
            setGames(state, data)
            {
                  state.games.data = data;
            },
            setGamesLoading(state, status)
            {
                  state.games.loading = status;
            },
            setGamesError(state, status)
            {
                  state.games.error = status;
            },
            setGamesErrorMessage(state, message)
            {
                  state.games.errorMessage = message;
            },
            setTeamGames(state, data)
            {
                  state.teamgames.data = data;
            },
            setTeamGamesLoading(state, status)
            {
                  state.teamgames.loading = status;
            },
            setTeamGamesError(state, status)
            {
                  state.teamgames.error = status;
            },
            setTeamGamesErrorMessage(state, message)
            {
                  state.teamgames.errorMessage = message;
            },
      },

      actions: {
            async getGames({ commit })
            {
                  commit("setGamesLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'games';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setGamesLoading", false);
                        commit("setGamesError", true);
                        commit("setGamesErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setGames", response.data.data);
                        commit("setGamesLoading", false);
                        commit("setGamesError", false);
                        commit("setGamesErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },

            async getTeamGames({ commit }, post)
            {
                  commit("setTeamGamesLoading", true);

                  var params = "?tid=" + post.tid;
                  if(post.vid)
                  params += "&vid="+post.vid;
                  let response;
                  try
                  {
                        let url = API_URL_GET + 'team-games' + params;
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setTeamGamesLoading", false);
                        commit("setTeamGamesError", true);
                        commit("setTeamGamesErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setTeamGames", response.data.data);
                        commit("setTeamGamesLoading", false);
                        commit("setTeamGamesError", false);
                        commit("setTeamGamesErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            }
      },

      namespaced: true
};
