import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_ADMIN = process.env.VUE_APP_API_URL_ADMIN;

export default {
      state: {
            tournament: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            },
            users: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            },
            groups: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            },
            games: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            },
            questions: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            },
            answers: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            },
            news: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            },
            payment: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            },
            winners: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            }
      },

      getters: {
            tournamentloading: state =>
            {
                  return state.tournament.loading;
            },
            tournament: state =>
            {
                  return state.tournament.data;
            },
            usersloading: state =>
            {
                  return state.users.loading;
            },
            users: state =>
            {
                  return state.users.data;
            },
            groupsloading: state =>
            {
                  return state.groups.loading;
            },
            groups: state =>
            {
                  return state.groups.data;
            },
            gamesloading: state =>
            {
                  return state.games.loading;
            },
            games: state =>
            {
                  return state.games.data;
            },
            questionsloading: state =>
            {
                  return state.questions.loading;
            },
            questions: state =>
            {
                  return state.questions.data;
            },
            answersloading: state =>
            {
                  return state.answers.loading;
            },
            answers: state =>
            {
                  return state.answers.data;
            },
            newsloading: state =>
            {
                  return state.news.loading;
            },
            news: state =>
            {
                  return state.news.data;
            },
            paymentloading: state =>
            {
                  return state.payment.loading;
            },
            payment: state =>
            {
                  return state.payment.data;
            },
            winnersloading: state =>
            {
                  return state.winners.loading;
            },
            winners: state =>
            {
                  return state.winners.data;
            }
      },

      mutations: {
            setTournament(state, data)
            {
                  state.tournament.data = data;
            },
            setTournamentLoading(state, status)
            {
                  state.tournament.loading = status;
            },
            setTournamentError(state, status)
            {
                  state.tournament.error = status;
            },
            setTournamentErrorMessage(state, message)
            {
                  state.tournament.errorMessage = message;
            },
            setUsers(state, data)
            {
                  for(var i = 0; i < data.length; i++)
                  {
                        data[i].label = data[i].username + ' - ' + data[i].email;
                  }
                  state.users.data = data;
            },
            setUsersLoading(state, status)
            {
                  state.users.loading = status;
            },
            setUsersError(state, status)
            {
                  state.users.error = status;
            },
            setUsersErrorMessage(state, message)
            {
                  state.users.errorMessage = message;
            },
            setGroups(state, data)
            {
                  state.groups.data = data;
            },
            setGroupsLoading(state, status)
            {
                  state.groups.loading = status;
            },
            setGroupsError(state, status)
            {
                  state.groups.error = status;
            },
            setGroupsErrorMessage(state, message)
            {
                  state.groups.errorMessage = message;
            },
            setGames(state, data)
            {
                  state.games.data = data;
            },
            setGamesLoading(state, status)
            {
                  state.games.loading = status;
            },
            setGamesError(state, status)
            {
                  state.games.error = status;
            },
            setGamesErrorMessage(state, message)
            {
                  state.games.errorMessage = message;
            },
            setQuestions(state, data)
            {
                  state.questions.data = data;
            },
            setQuestionsLoading(state, status)
            {
                  state.questions.loading = status;
            },
            setAnswers(state, data)
            {
                  state.answers.data = data;
            },
            setAnswersLoading(state, status)
            {
                  state.answers.loading = status;
            },
            setNews(state, data)
            {
                  state.news.data = data;
            },
            setNewsLoading(state, status)
            {
                  state.news.loading = status;
            },
            setPayment(state, data)
            {
                  state.payment.data = data;
            },
            setPaymentLoading(state, status)
            {
                  state.payment.loading = status;
            },
            setWinners(state, data)
            {
                  state.winners.data = data;
            },
            setWinnersLoading(state, status)
            {
                  state.winners.loading = status;
            }
      },

      actions: {
            async getTournament({ commit })
            {
                  commit("setTournamentLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_ADMIN + 'tournament-get';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setTournamentLoading", false);
                        commit("setTournamentError", true);
                        commit("setTournamentErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setTournament", response.data.data);
                        commit("setTournamentLoading", false);
                        commit("setTournamentError", false);
                        commit("setTournamentErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },

            async getUsers({ commit }, tournament)
            {
                  commit("setUsersLoading", true);
                  let response;
                  try
                  {
                        let url = API_URL_ADMIN + 'users-get';
                        if(tournament)
                              url += "?tournament=1";
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setUsersLoading", false);
                        commit("setUsersError", true);
                        commit("setUsersErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setUsers", response.data.data);
                        commit("setUsersLoading", false);
                        commit("setUsersError", false);
                        commit("setUsersErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },

            async getGroups({ commit })
            {
                  commit("setGroupsLoading", true);
                  let response;
                  try
                  {
                        let url = API_URL_ADMIN + 'groups-get';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setGroupsLoading", false);
                        commit("setGroupsError", true);
                        commit("setGroupsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setGroups", response.data.data);
                        commit("setGroupsLoading", false);
                        commit("setGroupsError", false);
                        commit("setGroupsErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },

            async getGames({ commit })
            {
                  commit("setGamesLoading", true);
                  let response;
                  try
                  {
                        let url = API_URL_ADMIN + 'games-get';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setGamesLoading", false);
                        commit("setGamesError", true);
                        commit("setGamesErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setGames", response.data.data);
                        commit("setGamesLoading", false);
                        commit("setGamesError", false);
                        commit("setGamesErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },

            async getQuestions({ commit })
            {
                  commit("setQuestionsLoading", true);
                  let response;
                  try
                  {
                        let url = API_URL_ADMIN + 'questions-get';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setQuestionsLoading", false);
                        // commit("setQuestionsError", true);
                        // commit("setQuestionsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setQuestions", response.data.data);
                        commit("setQuestionsLoading", false);
                        // commit("setQuestionsError", false);
                        // commit("setQuestionsErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },

            async getAnswers({ commit }, id)
            {
                  commit("setAnswersLoading", true);
                  let response;
                  try
                  {
                        let url = API_URL_ADMIN + 'user-answers-get?id=' + id;
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setAnswersLoading", false);
                        // commit("setQuestionsError", true);
                        // commit("setQuestionsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setAnswers", response.data.data);
                        commit("setAnswersLoading", false);
                        // commit("setQuestionsError", false);
                        // commit("setQuestionsErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },

            async getNews({ commit })
            {
                  commit("setNewsLoading", true);
                  let response;
                  try
                  {
                        let url = API_URL_ADMIN + 'news-get';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setNewsLoading", false);
                        // commit("setQuestionsError", true);
                        // commit("setQuestionsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setNews", response.data.data);
                        commit("setNewsLoading", false);
                        // commit("setQuestionsError", false);
                        // commit("setQuestionsErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },

            async getPayment({ commit })
            {
                  commit("setPaymentLoading", true);
                  let response;
                  try
                  {
                        let url = API_URL_ADMIN + 'payment-get';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setPaymentLoading", false);
                        // commit("setQuestionsError", true);
                        // commit("setQuestionsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setPayment", response.data.data);
                        commit("setPaymentLoading", false);
                        // commit("setQuestionsError", false);
                        // commit("setQuestionsErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },

            async getWinners({ commit })
            {
                  commit("setWinnersLoading", true);
                  let response;
                  try
                  {
                        let url = API_URL_ADMIN + 'winners-get';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setWinnersLoading", false);
                        // commit("setQuestionsError", true);
                        // commit("setQuestionsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setWinners", response.data.data);
                        commit("setWinnersLoading", false);
                        // commit("setQuestionsError", false);
                        // commit("setQuestionsErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },
      },

      namespaced: true
};
