import axios from "axios";

const API_URL_POST = process.env.VUE_APP_API_URL_POST;

export default {
      state: {
            register: {
                  message: "",
                  status: false,
                  loading: false
            },
            validationCode:{
                  message: "",
                  status: false,
                  loading: false                                  
            },
            completeRegister:
            {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null,
            },
            lookup:
            {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null
            }
      },

      getters: {
            registerloading: state =>
            {
                  return state.register.loading;
            },
            registersuccess: state =>
            {
                  return state.register.status;
            },
            registermessage: state =>
            {
                  return state.register.message;
            },
            validationCodeloading: state =>
            {
                  return state.validationCode.loading;
            },
            validationCodesuccess: state =>
            {
                  return state.validationCode.status;
            },
            validationCodemessage: state =>
            {
                  return state.validationCode.message;
            },
            lookup: state =>
            {
                  return state.lookup.data;
            }

      },

      mutations: {
            setRegisterStatus(state, status)
            {
                  state.register.status = status;
            },
            setRegisterLoading(state, status)
            {
                  state.register.loading = status;
            },
            setRegisterMessage(state, message)
            {
                  state.register.message = message;
            },
            setLookup(state, data)
            {
                  state.lookup.data = data;
            },
            setLookupLoading(state, status)
            {
                  state.lookup.loading = status;
            },
            setLookupError(state, status)
            {
                  state.lookup.error = status;
            },
            setLookupErrorMessage(state, message)
            {
                  state.lookup.errorMessage = message;
            },
            setValidationCodeSuccess(state, data)
            {
                  state.validationCode.status = data;
            },
            setValidationCodeLoading(state, status)
            {
                  state.validationCode.loading = status;
            },
            setValidationCodeMessage(state, message)
            {
                  state.validationCode.message = message;
            },
            setCompleteRegister(state, data)
            {
                  state.completeRegister.data = data;
            },
            setCompleteRegisterLoading(state, status)
            {
                  state.completeRegister.loading = status;
            },
            setCompleteRegisterError(state, status)
            {
                  state.completeRegister.error = status;
            },
            setCompleteRegisterErrorMessage(state, message)
            {
                  state.completeRegister.errorMessage = message;
            },
      },

      actions: {
            async registeraccount({ commit }, user)
            {
                  commit("setRegisterLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_POST + 'register';
                        response = await axios.post(url, user);
                  }
                  catch (error)
                  {
                        commit("setRegisterStatus", false);
                        commit("setRegisterLoading", false);                              
                        commit("setRegisterMessage", error.response.data);
                        return Promise.reject(error.response.data);
                  }
                  
                  if (response)
                  {                        
                        commit("setRegisterStatus", status);
                        commit("setRegisterMessage", response.data.message);
                        commit("setRegisterLoading", false);
                        return Promise.resolve(response.data);
                  }                 
            },

            async validateRegistrationCode({ commit }, item)
            {
                  commit("setValidationCodeLoading", true);

                  let response;
                  try
                  {
                        var params = new URLSearchParams();
                        params.append('code', item.code);
                        params.append('email', item.email);
                        let url = API_URL_POST + 'validateregistrationcode';
                        response = await axios.post(url, params);
                  }
                  catch (error)
                  {
                        commit("setValidationCodeSuccess", false);                        
                        commit("setValidationCodeMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {                        
                        commit("setValidationCodeSuccess", true);                        
                        commit("setValidationCodeMessage", "");
                        return Promise.resolve(response.data);
                  }
            },
         
            async lookup({ commit }, item)
            {
                  commit("setLookupLoading", true);
                  let url = API_URL_POST + 'lookup';
                  await axios.post(url, item, { headers: { "content-type": "application/x-www-form-urlencoded" } }).then(
                        (response) =>
                        {                              
                              commit("setLookup", JSON.parse(response.data.response));
                              commit("setLookupError", false);
                              commit("setLookupErrorMessage", "");
                              commit("setLookupLoading", false);
                              return Promise.resolve(true);
                        }
                  ).catch(
                        function (
                            error
                        ) {
                              commit("setLookup", false);
                              commit("setLookupError", true);
                              commit("setLookupLoading", false);                              
                              commit("setLookupErrorMessage", error.response.data);
                              return Promise.reject(error.response.data);
                        }
                    );
            },

            async completeRegistration({ commit }, user)
            {
                  commit("setCompleteRegisterLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_POST + 'complete-register';
                        response = await axios.post(url, user);
                  }
                  catch (error)
                  {
                        commit("setCompleteRegisterLoading", false);
                        commit("setCompleteRegisterError", true);
                        commit("setCompleteRegisterErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setCompleteRegisterLoading", false);
                        commit("setCompleteRegisterError", false);
                        commit("setCompleteRegisterErrorMessage", "");
                        return Promise.resolve(response);
                  }
            }            
      },

      namespaced: true
};
