import moment from "moment";

export default {
    // return expire date in readable format
    // Parameter: "2011-04-11T10:20:30Z" or "2011-04-11T10:20:30" (without Z at the end)
    // Return: "2020-04-11"
    stringToReadableFormat(dateString) {
        // Removed code below, in case you evr want to return "torsdag 13 augusti 2020" then uncomment code below
        // Parse string into date object

        const date = moment(dateString).toDate();
        //const date = new Date(dstring);

        // Convert to readable date string
        const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric"
        };
        let readableDateString = date.toLocaleDateString("sv-SE", options);

        // Convert first letter in weekday into uppercase
        readableDateString =
            readableDateString.charAt(0).toUpperCase() +
            readableDateString.slice(1);

        return readableDateString;
        //return this.stringToIsoDateOnly(dateString);
    },
    stringToReadableFormatShort(dateString) {
        // Removed code below, in case you ever want to return "torsdag 13 augusti 2020" then uncomment code below
        // Parse string into date object
        const date = moment(dateString).toDate();

        // Convert to readable date string
        const options = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric"
        };
        let readableDateString = date.toLocaleDateString("sv-SE", options);

        // Convert first letter in weekday into uppercase
        readableDateString =
            readableDateString.charAt(0).toUpperCase() +
            readableDateString.slice(1);

        return readableDateString;
        //return this.stringToIsoDateOnly(dateString);
    },
    stringToReadableFormatWithTime(dateString) {
        // Removed code below, in case you ever want to return "torsdag 13 augusti 2020" then uncomment code below
        // Parse string into date object
        const date = moment(dateString).toDate();
        //const date = new Date(dstring);

        // Convert to readable date string
        const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit"
        };
        let readableDateString = date.toLocaleDateString("sv-SE", options);

        // Convert first letter in weekday into uppercase
        readableDateString =
            readableDateString.charAt(0).toUpperCase() +
            readableDateString.slice(1);

        return readableDateString;
        //return this.stringToIsoDateOnly(dateString);
    },
    stringToReadableFormatWithTimeShort(dateString) {
        // Removed code below, in case you ever want to return "torsdag 13 augusti 2020" then uncomment code below
        // Parse string into date object
        const date = moment(dateString).toDate();
        // Convert to readable date string
        const options = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit"
        };
        let readableDateString = date.toLocaleDateString("sv-SE", options);

        // Convert first letter in weekday into uppercase
        readableDateString =
            readableDateString.charAt(0).toUpperCase() +
            readableDateString.slice(1);

        return readableDateString;
        //return this.stringToIsoDateOnly(dateString);
    },
    // returns YYYY-MM-DD
    stringToIsoDateOnly(dateString) {
        // Parse string into date object
        const date = moment(dateString).toDate();

        // Convert to readable date string
        const options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        };
        const isoDateString = date.toLocaleDateString("sv-SE", options);
        return isoDateString;
    },
    utcDateToSwedishDate(utcDate) {
        var swedishDate = moment(utcDate).add(2, "hours");
        return swedishDate;
    },
    utcDateToLocalDate(utcDate)
    {
        var localDate = moment(utcDate).add(this.userTimezone(), "hours");
        return localDate;
    },
    dateNow() {
        return moment(Date.now()).format("YYYY-MM-DD HH:mm");
    },
    dateNowWithoutTime() {
        return moment(Date.now()).format("YYYY-MM-DD");
    },
    dateNowTimestamp() {
        return moment(Date.now()).format("x");
    },
    dateToTimestamp(date) {
        return moment(date).format("x");
    },
    timestampToDays(time) {
        return parseInt(time / 86400);
    },
    dateToDateWithoutHour(date) {
        return moment(date).format("YYYY-MM-DD");
    },
    dateToDateWithoutSeconds(date) {
        if (moment(date).format("YYYY-MM-DD HH:mm") != "Invalid date") {
            return moment(date).format("YYYY-MM-DD HH:mm");
        } else {
            const newDate = parseInt(date);
            return moment(newDate).format("YYYY-MM-DD HH:mm");
        }
    },
    // Difference in seconds between two date objects
    // positive value means dateA is a earlier datetime than dateB
    secondDifferenceBetweenDates(dateA, dateB) {
        const a = moment(dateA);
        const b = moment(dateB);
        return moment(b).diff(moment(a), "seconds");
    },
    // Difference in days between two date objects
    dayDifferenceBetweenDates(dateA, dateB) {
        const a = moment(dateA);
        const b = moment(dateB);
        return moment(b).diff(moment(a), "days");
    },
    daysBefore(date, days) {
        if (!days && days !== 0) {
            days = 1;
        }
        return moment(date)
            .subtract(days, "days")
            .format("YYYY-MM-DD HH:mm");
    },
    daysAfter(date, days) {
        if (!days) {
            days = 1;
        }
        return moment(date)
            .add(days, "days")
            .format("YYYY-MM-DD HH:mm");
    },
    lastWeek() {
        const dateNow = this.dateNow();
        return moment(dateNow)
            .subtract(1, "weeks")
            .format("YYYY-MM-DD HH:mm");
    },
    getFormatedTime(date) {
        return moment(date).format("HH:mm");
    },
    getFormatedDate(date) {
        return moment(date).format("YYYY-MM-DD HH:mm");
    },
    getFormatedDateWithoutTime(date) {
        return moment(date).format("MMM DD, YYYY");
    },
    userTimezone() {
        // Returns time zone difference in minutes, eg -60 for UTC +1
        const timedifference = new Date().getTimezoneOffset();

        // Invert the result and divide by 60 to get in hours. Eg, -60 becomes +1
        return (timedifference * -1) / 60;
    },
    currentYear()
    {
        return moment().format("YYYY");
    },
    TimeLeft(date){
        var end = moment(date);
        var diff = moment.duration(end.diff(moment()));
        return diff.asMilliseconds();
    },
    TeamGroupDate(dateString) {
        // Removed code below, in case you ever want to return "torsdag 13 augusti 2020" then uncomment code below
        // Parse string into date object
        const date = moment(dateString).toDate();

        // Convert to readable date string
        const options = {            
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit"
        };
        let readableDateString = date.toLocaleDateString("sv-SE", options);

        // Convert first letter in weekday into uppercase
        readableDateString =
            readableDateString.charAt(0).toUpperCase() +
            readableDateString.slice(1);

        return readableDateString;
        //return this.stringToIsoDateOnly(dateString);
    },
    GameDate(dateString) {        
        return moment(dateString).format("YY-MM-DD");
    },
    GameDateFull(dateString){
        return moment(dateString).format("YYYY-MM-DD");
    },
    GameTime(dateString) {        
        return moment(dateString).format("HH:mm");
    },
    isOpen(datestring){
        return moment().isBefore(datestring);
    },
    dateAsId(datestring){
        return moment(datestring).format("YY_MM_DD");
    },
    custom(date, format){
        return moment(date).format(format);
    },
    ticks(){
        return moment().unix();
    }

};
