export default {
      FormatTime(n)
      {
            var num = n;
            var hours = (num / 60);
            var rhours = Math.floor(hours);
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);
            var hourText = rhours > 1 ? "timmar" : "timme";
            var minutesText = rminutes > 1 ? "minuter" : "minut";
            if (rhours > 0)
                  if (rminutes > 0)
                        return rhours + " " + hourText + " " + rminutes + " " + minutesText;
                  else
                        return rhours + " " + hourText;
            return rminutes + " " + minutesText;
      }
};
