import Vue from 'vue';
import store from "../store/";
import Router from 'vue-router';
import functions from "../helpers/functions";

/* FRONT */
const FrontMaster = () => import(/* webpackChunkName: "frontmaster" */ "../views/master/FrontMaster.vue");
const Login = () => import(/* webpackChunkName: "login" */ "../views/front/Login.vue");
const ForgotPassword = () => import(/* webpackChunkName: "forgotpassword" */ "../views/front/ForgotPassword.vue");
const Register = () => import(/* webpackChunkName: "register" */ "../views/front/Register.vue");

/* APP */
const AppMaster = () => import(/* webpackChunkName: "appmaster" */ "../views/master/AppMaster.vue");
const Home = () => import(/* webpackChunkName: "home" */ "../views/home/Home.vue");
const Tournaments = () => import(/* webpackChunkName: "tournaments" */ "../views/tournaments/Tournaments.vue");
const Results = () => import(/* webpackChunkName: "tournaments" */ "../views/games/Results.vue");
const Users = () => import(/* webpackChunkName: "users" */ "../views/users/Users.vue");
const User = () => import(/* webpackChunkName: "user" */ "../views/users/User.vue");
const Groups = () => import(/* webpackChunkName: "groups" */ "../views/groups/Groups.vue");
const Tipsligan = () => import(/* webpackChunkName: "tipsligan" */ "../views/stats/Tipsligan.vue");
const Statistics = () => import(/* webpackChunkName: "statistics" */ "../views/stats/Tournamentstats.vue");
const Winners = () => import(/* webpackChunkName: "winners" */ "../views/winners/Winners.vue");
const Profile = () => import(/* webpackChunkName: "profile" */ "../views/profile/Profile.vue");

/* TIPS */
// VISA
const ViewGames = () => import(/* webpackChunkName: "viewgames" */ "../views/tips/view/games/Games");
const ViewQuestions = () => import(/* webpackChunkName: "viewquestions" */ "../views/tips/view/questions/Questions");
// TIPSA
const TipsGames = () => import(/* webpackChunkName: "tipsgames" */ "../views/tips/add/games/Games");
const TipsQuestions = () => import(/* webpackChunkName: "tipsquestions" */ "../views/tips/add/questions/Questions");

const Team = () => import(/* webpackChunkName: "team" */ "../views/team/Team.vue");
const Gbook = () => import(/* webpackChunkName: "gbook" */ "../views/gbook/Gbook.vue");
const Error = () => import(/* webpackChunkName: "error" */ "../views/miscellaneous/Error.vue");


/* ADMINISTRATOR */
const Administrator_Tournaments = () => import(/* webpackChunkName: "administratortournaments" */ "../views/administrator/Tournaments.vue");
const Administrator_Users = () => import(/* webpackChunkName: "administratorusers" */ "../views/administrator/Users.vue");
const Administrator_Logs = () => import(/* webpackChunkName: "administratorlogs" */ "../views/administrator/Logs.vue");
const Administrator_SMS = () => import(/* webpackChunkName: "administratorsms" */ "../views/administrator/SMS.vue");

/* ADMIN */
const Admin_Tournament = () => import(/* webpackChunkName: "admin" */ "../views/admin/Tournament.vue");
const Admin_Groups = () => import(/* webpackChunkName: "admin" */ "../views/admin/Groups.vue");
const Admin_Games = () => import(/* webpackChunkName: "admin" */ "../views/admin/Games.vue");
const Admin_Questions = () => import(/* webpackChunkName: "admin" */ "../views/admin/Questions.vue");
const Admin_Results = () => import(/* webpackChunkName: "admin" */ "../views/admin/Results.vue");
const Admin_Answers = () => import(/* webpackChunkName: "admin" */ "../views/admin/Answers.vue");
const Admin_Users = () => import(/* webpackChunkName: "admin" */ "../views/admin/Users.vue");
const Admin_Invite = () => import(/* webpackChunkName: "admin" */ "../views/admin/Invite.vue");
const Admin_News = () => import(/* webpackChunkName: "admin" */ "../views/admin/News.vue");
const Admin_Payment = () => import(/* webpackChunkName: "admin" */ "../views/admin/Payment.vue");
const Admin_Winners = () => import(/* webpackChunkName: "admin" */ "../views/admin/Winners.vue");

// import store from "./store";

Vue.use(Router);
// vue meta
import Meta from 'vue-meta'
Vue.use(Meta);

export const router = new Router({
  mode: 'history',
  routes: [
    {
      name: "frontmaster",
      path: "/loggain",
      component: FrontMaster,
      redirect: { path: "/loggain" },
      children: [{
        path: "/loggain",
        name: "Loggain",
        component: Login
      },
      {
        path: "/registrera",
        name: "Register",
        component: Register
      },
      {
        path: "/glomtlosenord",
        name: "GlomtLosenord",
        component: ForgotPassword
      }]
    },
    {
      name: "appmaster",
      path: "/",
      component: AppMaster,
      redirect: { path: "/" },
      children: [
        {
          path: "/",
          name: "Home",
          component: Home
        },
        {
          path: "/start",
          name: "Start",
          component: Home
        },
        {
          path: "/profil",
          name: "Profile",
          component: Profile
        },
        {
          name: "TipsGames",
          path: "/tipsa/matcher/",
          component: TipsGames
        },
        {
          name: "TipsQuestions",
          path: "/tipsa/fragor/",
          component: TipsQuestions
        },
        {
          name: "ViewGames",
          path: "/visa/matcher",
          component: ViewGames
        },
        {
          name: "ViewGame",
          path: "/visa/matcher/:id",
          component: ViewGames
        },
        {
          name: "ViewGame",
          path: "/visa/matcher/:id/:uid",
          component: ViewGames
        },
        {
          name: "ViewQuestions",
          path: "/visa/fragor/",
          component: ViewQuestions
        },
        {
          name: "ViewQuestion",
          path: "/visa/fragor/:id",
          component: ViewQuestions
        },
        {
          name: "ViewQuestion",
          path: "/visa/fragor/:id/:uid",
          component: ViewQuestions
        },
        {
          path: "/turneringar",
          name: "Tournaments",
          component: Tournaments
        },
        {
          path: "/deltagare",
          name: "Users",
          component: Users
        },
        {
          path: "/resultat",
          name: "Results",
          component: Results
        },
        {
          path: "/tipsligan",
          name: "Tipsligan",
          component: Tipsligan
        },
        {
          path: "/statistik",
          name: "Statistik",
          component: Statistics
        },
        {
          path: "/vinster",
          name: "Winners",
          component: Winners
        },
        {
          path: "/deltagare/:username",
          name: "User",
          component: User
        },
        {
          path: "/grupper",
          name: "Groups",
          component: Groups
        },
        {
          path: "/gastbok",
          name: "Gbook",
          component: Gbook
        },
        {
          path: "/lag/*",
          name: "Team",
          component: Team
        },
        {
          name: "administrator_tournaments",
          path: "/administrator/turneringar",
          component: Administrator_Tournaments          
        },
        {
          name: "administrator_users",
          path: "/administrator/anvandare",
          component: Administrator_Users          
        },
        {
          name: "administrator_logs",
          path: "/administrator/loggar",
          component: Administrator_Logs          
        },
        {
          name: "administrator_sms",
          path: "/administrator/sms",
          component: Administrator_SMS          
        },
        {
          name: "admin_tournament",
          path: "/admin/turnering",
          component: Admin_Tournament
        },        
        {
          name: "admin_groups",
          path: "/admin/grupper",
          component: Admin_Groups
        },
        {
          name: "admin_games",
          path: "/admin/matcher",
          component: Admin_Games
        },
        {
          name: "admin_questions",
          path: "/admin/fragor",
          component: Admin_Questions
        },
        {
          name: "admin_results",
          path: "/admin/resultat",
          component: Admin_Results
        },
        {
          name: "admin_users",
          path: "/admin/anvandare",
          component: Admin_Users
        },
        {
          name: "admin_invite",
          path: "/admin/invite",
          component: Admin_Invite
        },
        {
          name: "admin_news",
          path: "/admin/nyheter",
          component: Admin_News
        },
        {
          name: "admin_answers",
          path: "/admin/svar",
          component: Admin_Answers
        },
        {
          name: "admin_payment",
          path: "/admin/betalning",
          component: Admin_Payment
        },
        {
          name: "admin_winners",
          path: "/admin/vinnare",
          component: Admin_Winners
        },
        {
          path: "*",
          component: Error
        }
      ]
    }
  ],
  scrollBehavior(to, from, savedPosition)
    {
        if (savedPosition)
        {
            return savedPosition;
        } else
        {
            return { x: 0, y: 0 };
        }
      }
});

router.beforeEach((to, from, next) =>
{
  const publicPages = ['/loggain', '/glomtlosenord', '/registrera'];
  const noTournamentPages = ['/profil', '/administration', '/turneringar'];
  const loggedIn = localStorage.getItem('user');
  const hasTournament = functions.hasTournament();
  var isPublicPage = checkPage(publicPages, to.path);
  var isNoTournamentPage = checkPage(noTournamentPages, to.path);

  if (isPublicPage)
  {
    next();
    return;
  }

  if (loggedIn)
  {
    if (!hasTournament && !isNoTournamentPage)
      next({ path: "/turneringar" });
    else next();
  }

  // If not logged in and trying to access a page that is nog public
  if (!loggedIn)
  {
    store.dispatch("auth/logout");
    if (to.path != "" && to.path != "/")
      next({ path: "/loggain", query: { redirect: to.path } }); // Redirect to login page
    else
      next({ path: "/loggain" }); // Redirect to login page
    return;
  }
  // If accessing root path "/"
  else if (to.path === "/")
  {
    if (loggedIn)
    {
      next(); // Redirect after logged in
    }
    else
    {
      next({ path: "/loggain" }); // Redirect to login page
    }
    return;
  }

});

function checkPage(pages, path)
{
  for (var i = 0; i < pages.length; i++)
  {
    var page = pages[i];
    if (path.includes(page))
      return true;
  }
  return false;
}
