<template>
<b-popover :target="target" :title="user.username" placement="topleft" triggers="click blur" @show="show">
    <div class="p-1">
         <div class="px-2 py-1 pointer text-primary hoverable rounded" @click="GoToGames">
          Visa matcher
        </div>
        <div class="px-2 py-1 pointer text-primary hoverable rounded" @click="GoToQuestions">
          Visa frågor
        </div>
        <div class="px-2 py-1 pointer text-primary hoverable rounded" @click="GoToUser">
            Visa profil
        </div>
        <div class="px-2 py-1 text-danger pointer hoverable danger rounded" @click="show">
          Stäng
        </div>
        
    </div>
</b-popover>
</template>

<script>
export default {
    name: "UserPopover",
    props: ["user", "target"],
    components: {},
    methods: {
        GoToUser() {
            var url = this.$url.userurl(this.user);
            this.$router.push(url);
        },
        GoToGames(){
          this.$router.push("/visa/matcher/alla/" + this.user.userid);
        },
        GoToQuestions(){
          this.$router.push("/visa/fragor/alla/" + this.user.userid);
        },
        show(){
          this.$root.$emit('bv::hide::popover');
        }
    }
}
</script>
