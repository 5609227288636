import axios from "axios";
import store from "../store";

export const setupAxios = function ()
{
      // Global timeout and number of retries for requests
      axios.defaults.timeout = 30000;
      axios.defaults.retry = 5;

      axios.interceptors.response.use(undefined, function axiosRetryInterceptor(
            error
      )
      {
            const config = error.config;

            // If config does not exist or the retry option is not set, reject
            if (!config || !config.retry)
            {
                  return Promise.reject(error);
            }

            // Don't retry if unauthorized
            if (
                  error.response &&
                  (error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 404)
            )
            {
                  return Promise.reject(error);
            }

            // Set variable for keeping track of the retry count
            config.retryCount = config.retryCount || 0;

            // Check if number of retries are reached
            if (config.retryCount >= config.retry)
            {
                  return Promise.reject(error);
            }

            config.retryCount += 1;

            return new Promise(resolve =>
                  setTimeout(() => resolve(axios(config)), 1000)
            );
      });

      axios.interceptors.response.use(
            undefined,
            function axiosUnauthorizedInterceptor(error)
            {
                  //console.log("testaxios->", error);
                  if (
                        error.response &&
                        (error.response.status === 401 || error.response.status === 403)
                  )
                  {
                        //alert("Tyvärr har sessionen gått ut. Var snäll logga in igen");
                        if(error.response.status === 401) // ej inloggad
                        {
                              cancelRequests(
                                    CancelTokenTypes.ALL,
                                    "Tyvärr har sessionen gått ut. Var snäll logga in igen"
                              );
                              store.dispatch("auth/logout");
                              //window.location.href = "/";
                        }
                        // else if(error.response.status === 403) // ej admin
                        // {
                              
                        // }
                  }

                  return Promise.reject(error);
            }
      );
};

let cancelTokens = {};

export const CancelTokenTypes = Object.freeze({
      ALL: "ALL"
});

export const getCancelToken = function (type)
{
      if (!CancelTokenTypes.hasOwnProperty(type))
      {
            throw "Invalid CancelToken type.";
      }
      if (type === CancelTokenTypes.ALL)
      {
            throw "Cannot get cancel token of type ALL."; // Do not allow assign "ALL" cancelTokens, only use for cancelling;
      }

      const newCancelToken = axios.CancelToken.source();

      if (!cancelTokens.hasOwnProperty(type))
      {
            cancelTokens[type] = [];
      }

      cancelTokens[type].push(newCancelToken);

      return newCancelToken;
};

/**
 * Function to cancel pending axios requests
 *
 * @param {string} type Type of request to cancel, or ALL for all.
 * @param {string} reason Message containing reason for cancelling requests.
 */
export const cancelRequests = function (type, reason = "Request cancelled.")
{
      let cancelledRequests = 0;
      if (type === CancelTokenTypes.ALL)
      {
            // Handle cancelling all requests through recursion
            for (const innerType in cancelTokens)
            {
                  cancelledRequests += cancelRequests(innerType, reason);
            }
      } else if (cancelTokens.hasOwnProperty(type))
      {
            for (const cancelToken of cancelTokens[type])
            {
                  cancelToken.cancel(reason);
                  cancelledRequests++;
            }

            cancelTokens[type] = [];
      }

      return cancelledRequests;
};
