import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_GET = process.env.VUE_APP_API_URL_GET;

export default {
      state: {
            profile: {
                  error: false,
                  errorMessage: "",
                  loading: true,
                  data: null
            },
            user: {
                  error: false,
                  errorMessage: "",
                  loading: true,
                  data: null
            }
      },

      getters: {
            loading: state => {
                  return state.profile.loading;
            },
            profile: state => {
                  return state.profile.data;
            },
            userLoading: state => {
                  return state.user.loading;
            },
            user: state => {
                  return state.user.data;
            }

      },

      mutations: {
            setEmail(state, email) {
                  state.profile.data.email = email;
            },
            setProfile(state, data) {
                  state.profile.data = data;
            },
            setProfileLoading(state, status) {
                  state.profile.loading = status;
            },
            setProfileError(state, status) {
                  state.profile.error = status;
            },
            setProfileErrorMessage(state, message) {
                  state.profile.errorMessage = message;
            },
            setByKey(state, { key, value }) {
                  state.profile.data[key] = value;
            },
            setUserLoading(state, loading) {
                  state.user.loading = loading;
            },
            setUser(state, data) {
                  state.user.data = data;
            }
      },

      actions: {
            async getProfile({ commit }) {
                  commit("setProfileLoading", true);

                  let response;
                  try {
                        let url = API_URL_GET + 'profile';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error) {
                        commit("setProfileLoading", false);
                        commit("setProfileError", true);
                        commit("setProfileErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response) {
                        commit("setProfile", response.data.data);
                        commit("setProfileLoading", false);
                        commit("setProfileError", false);
                        commit("setProfileErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },
            async getUser({ commit }, username) {
                  commit("setUserLoading", true);

                  let response;
                  try {
                        let url = API_URL_GET + 'user?username=' + username;
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error) {
                        commit("setUserLoading", false);
                        //commit("setProfileError", true);
                        //commit("setProfileErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response) {
                        commit("setUser", response.data.data);
                        commit("setUserLoading", false);
                        //commit("setProfileError", false);
                        //commit("setProfileErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },

            async checkUserLogin() {
                  let response;
                  try {
                        let url = API_URL_GET + 'checklogin';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error) {
                        return Promise.reject(error);
                  }
                  if (response) {
                        return Promise.resolve(response);
                  }
            },
      },

      namespaced: true
};
