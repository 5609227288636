import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_POST = process.env.VUE_APP_API_URL_POST;

export default {
      state: {
            savequestion:{
                  loading: false
            },
            savetips: {
                  loading: false
            },
      },

      getters: {
            questionloading: state =>
            {
                  return state.savequestion.loading;
            },
            tipsloading: state =>
            {
                  return state.savetips.loading;
            }
      },

      mutations: {            
            setQuestionLoading(state, status)
            {
                  state.savequestion.loading = status;
            },
            setTipsLoading(state, status){
                  state.savetips.loading = status;
            }            
      },

      actions: {
            async SaveQuestion({ commit }, post)
            {
                  commit("setQuestionLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_POST + 'user-question-save';
                        response = await axios.post(url, post, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setQuestionLoading", false);
                        // commit("setTournamentsError", true);
                        // commit("setTournamentsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {                        
                        commit("setQuestionLoading", false);                        
                        return Promise.resolve(response);
                  }                  
            },

            async SaveTips({ commit }, post)
            {
                  commit("setTipsLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_POST + 'user-tips-save';
                        response = await axios.post(url, post, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setTipsLoading", false);
                        // commit("setTournamentsError", true);
                        // commit("setTournamentsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {                        
                        commit("setTipsLoading", false);                        
                        return Promise.resolve(response);
                  }                  
            },
      },

      namespaced: true
};
