/* eslint-disable */

export default {
      url(url)
      {
            if (!url)
                  url = "";
            const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
            const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
            const p = new RegExp(a.split('').join('|'), 'g')

            return url.toString().toLowerCase()
                  .replace(/\s+/g, '-') // Replace spaces with -
                  .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
                  .replace(/&/g, '-and-') // Replace & with 'and'
                  .replace(/[^\w\-]+/g, '') // Remove all non-word characters
                  .replace(/\-\-+/g, '-') // Replace multiple - with single -
                  .replace(/^-+/, '') // Trim - from start of text
                  .replace(/-+$/, '') // Trim - from end of text
      },
      teamurl(team)
      {          
          return "/lag/" + this._teamlink(team);
      },
      _teamlink(team)
      {
            return team.id+"-"+team.shortname;
      },
      teamvsurl(team1, team2)
      {
            return "/lag/" + this._teamlink(team1) + "/" + this._teamlink(team2);
      },
      userurl(user){
            return "/deltagare/" + encodeURIComponent(user.username);
      }
};
