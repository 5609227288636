import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_ADMIN = process.env.VUE_APP_API_URL_ADMIN;

export default {
      state: {
            groupadd: {
                  error: false,
                  errors: [],
                  loading: false,
                  data: null
            },
            tournamentadd: {
                  error: false,
                  errors: [],
                  loading: false,
                  data: null
            },
            paymentadd: {
                  error: false,
                  errors: [],
                  loading: false,
                  data: null
            },
            gameadd: {
                  error: false,
                  errors: [],
                  loading: false,
                  data: null
            },
            questionadd: {
                  error: false,
                  errors: [],
                  loading: false,
                  data: null
            },
            uploadimage: {
                  loading: false
            },
            rotateimage: {
                  loading: false
            },
            common: {
                  loading: false
            }
      },

      getters: {
            tournamentloading: state =>
            {
                  return state.tournamentadd.loading;
            },
            tournamenterror: state =>
            {
                  return state.tournamentadd.error;
            },
            tournamenterrors: state =>
            {
                  return state.tournamentadd.errors;
            },
            tournament: state =>
            {
                  return state.tournamentadd.data;
            },
            paymentloading: state =>
            {
                  return state.paymentadd.loading;
            },
            paymenterror: state =>
            {
                  return state.paymentadd.error;
            },
            paymenterrors: state =>
            {
                  return state.paymentadd.errors;
            },
            payment: state =>
            {
                  return state.paymentadd.data;
            },
            grouploading: state =>
            {
                  return state.groupadd.loading;
            },
            grouperror: state =>
            {
                  return state.groupadd.error;
            },
            grouperrors: state =>
            {
                  return state.groupadd.errors;
            },
            group: state =>
            {
                  return state.groupadd.data;
            },
            gameloading: state =>
            {
                  return state.gameadd.loading;
            },
            gameerror: state =>
            {
                  return state.gameadd.error;
            },
            gameerrors: state =>
            {
                  return state.gameadd.errors;
            },
            game: state =>
            {
                  return state.gameadd.data;
            },
            questionloading: state =>
            {
                  return state.questionadd.loading;
            },
            questionerror: state =>
            {
                  return state.questionadd.error;
            },
            questionerrors: state =>
            {
                  return state.questionadd.errors;
            },
            question: state =>
            {
                  return state.questionadd.data;
            },
            imageloading: state =>
            {
                  return state.uploadimage.loading;
            },
            rotateloading: state =>
            {
                  return state.rotateimage.loading;
            },
            commonloading: state => {
                  return state.common.loading;
            }
      },

      mutations: {
            setTournamentAddLoading(state, status)
            {
                  state.tournamentadd.loading = status;
            },
            setTournamentAdd(state, data)
            {
                  state.tournamentadd.data = data;
            },
            setTournamentAddError(state, status)
            {
                  state.tournamentadd.error = status;
            },
            setTournamentAddErrors(state, errors)
            {
                  state.tournamentadd.errors = errors;
            },
            setPaymentAddLoading(state, status)
            {
                  state.paymentadd.loading = status;
            },
            setPaymentAdd(state, data)
            {
                  state.paymentadd.data = data;
            },
            setPaymentAddError(state, status)
            {
                  state.paymentadd.error = status;
            },
            setPaymentAddErrors(state, errors)
            {
                  state.paymentadd.errors = errors;
            },
            setGroupAddLoading(state, status)
            {
                  state.groupadd.loading = status;
            },
            setGroupAdd(state, data)
            {
                  state.groupadd.data = data;
            },
            setGroupAddError(state, status)
            {
                  state.groupadd.error = status;
            },
            setGroupAddErrors(state, errors)
            {
                  state.groupadd.errors = errors;
            },
            setGameAdd(state, data)
            {
                  state.gameadd.data = data;
            },
            setGameAddLoading(state, status)
            {
                  state.gameadd.loading = status;
            },
            setGameAddError(state, status)
            {
                  state.gameadd.error = status;
            },
            setGameAddErrors(state, errors)
            {
                  state.gameadd.errors = errors;
            },
            setQuestionAdd(state, data)
            {
                  state.questionadd.data = data;
            },
            setQuestionAddLoading(state, status)
            {
                  state.questionadd.loading = status;
            },
            setQuestionAddError(state, status)
            {
                  state.questionadd.error = status;
            },
            setQuestionAddErrors(state, errors)
            {
                  state.questionadd.errors = errors;
            },
            setUploadImageLoading(state, status)
            {
                  state.uploadimage.loading = status;
            },
            setRotateImageLoading(state, status)
            {
                  state.rotateimage.loading = status;
            },
            setCommonLoading(state, value)
            {
                  state.common.loading = value;
            }
      },

      actions: {

            async AddTournament({ commit }, data)
            {
                  commit("setTournamentAddLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_ADMIN + 'tournament-post';
                        response = await axios.post(url, data, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setTournamentAddLoading", false);
                        commit("setTournamentAddError", true);
                        commit("setTournamentAddErrors", error.errors);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setTournamentAdd", response.data.data);
                        commit("setTournamentAddLoading", false);
                        commit("setTournamentAddError", response.data.status != "ok");
                        commit("setTournamentAddErrors", response.data.errors);
                        return Promise.resolve(response.data);
                  }
            },

            async RotateTournamentImage({ commit }, entry)
            {
                  commit("setRotateImageLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_ADMIN + 'tournament-image-rotate';
                        response = await axios.post(url, entry, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setRotateImageLoading", false);
                        // commit("setTournamentsError", true);
                        // commit("setTournamentsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setRotateImageLoading", false);
                        return Promise.resolve(response);
                  }
            },

            async uploadTournamentImage({ commit }, file)
            {
                  let headers = authHeader();
                  headers["Content-Type"] = 'multipart/form-data';
                  //const config = { headers: { 'Content-Type': 'multipart/form-data' } };
                  const config = { headers: headers };

                  let fd = new FormData();
                  fd.append('file', file)

                  commit("setUploadImageLoading", true);
                  return axios.post(API_URL_ADMIN + 'tournament-image-upload', fd, config).then(function (response)
                  {
                        commit("setUploadImageLoading", false);
                        if (response.data.Success)
                        {
                              // commit("setError", false);
                        } else
                        {
                              // commit("setError", true);
                        }
                        return Promise.resolve(response);
                  })
                        .catch(function (error)
                        {
                              commit("setUploadImageLoading", false);
                              // commit("setError", true);
                              // commit("setErrorMessage", "Network error");

                              return Promise.reject(error);
                        });
            },

            async AddGroup({ commit }, data)
            {
                  commit("setGroupAddLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_ADMIN + 'group-post';
                        response = await axios.post(url, data, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setGroupAddLoading", false);
                        commit("setGroupAddError", true);
                        commit("setGroupAddErrors", error.errors);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setGroupAdd", response.data.data);
                        commit("setGroupAddLoading", false);
                        commit("setGroupAddError", response.data.status != "ok");
                        commit("setGroupAddErrors", response.data.errors);
                        return Promise.resolve(response.data);
                  }
            },

            async AddGroupTeam({ commit }, data)
            {
                  let response;
                  commit("setCommonLoading", true);
                  try
                  {
                        let url = API_URL_ADMIN + 'group-add-team';
                        response = await axios.post(url, data, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setCommonLoading", false);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setCommonLoading", false);
                        return Promise.resolve(response.data);
                  }
            },

            async DeleteGroupTeam({ commit }, data)
            {
                  let response;
                  commit("setCommonLoading", true);
                  try
                  {
                        let url = API_URL_ADMIN + 'group-delete-team';
                        response = await axios.post(url, data, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setCommonLoading", false);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setCommonLoading", false);
                        return Promise.resolve(response.data);
                  }
            },

            async AddGame({ commit }, data)
            {
                  commit("setGameAddLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_ADMIN + 'game-post';
                        response = await axios.post(url, data, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setGameAddLoading", false);
                        commit("setGameAddError", true);
                        commit("setGameAddErrors", error.errors);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setGameAdd", response.data.data);
                        commit("setGameAddLoading", false);
                        commit("setGameAddError", response.data.status != "ok");
                        commit("setGameAddErrors", response.data.errors);
                        return Promise.resolve(response.data);
                  }
            },

            async SetResult({ commit }, data)
            {
                  let response;
                  commit("setCommonLoading", true);
                  try
                  {
                        let url = API_URL_ADMIN + 'game-result-set';
                        response = await axios.post(url, data, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setCommonLoading", false);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setCommonLoading", false);
                        return Promise.resolve(response.data);
                  }
            },

            async AddQuestion({ commit }, data)
            {
                  commit("setQuestionAddLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_ADMIN + 'question-post';
                        response = await axios.post(url, data, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setQuestionAddLoading", false);
                        commit("setQuestionAddError", true);
                        commit("setQuestionAddErrors", error.errors);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setQuestionAdd", response.data.data);
                        commit("setQuestionAddLoading", false);
                        commit("setQuestionAddError", response.data.status != "ok");
                        commit("setQuestionAddErrors", response.data.errors);
                        return Promise.resolve(response.data);
                  }
            },

            async SetUserAnswer({ commit }, data)
            {
                  let response;
                  commit("setCommonLoading", true);
                  try
                  {
                        let url = API_URL_ADMIN + 'user-answer-post';
                        response = await axios.post(url, data, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setCommonLoading", false);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setCommonLoading", false);
                        return Promise.resolve(response.data);
                  }
            },

            async AddNewsEntry({ commit }, data)
            {
                  commit("setCommonLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_ADMIN + 'news-post';
                        response = await axios.post(url, data, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setCommonLoading", false);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setCommonLoading", false);
                        return Promise.resolve(response.data);
                  }
            },

            async InviteUsers({ commit }, data)
            {
                  commit("setCommonLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_ADMIN + 'tournament-invite';
                        response = await axios.post(url, data, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setCommonLoading", false);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setCommonLoading", false);
                        return Promise.resolve(response.data);
                  }
            },

            async SetUserTournamentAccess({ commit }, data)
            {
                  let response;
                  commit("setCommonLoading", true);
                  try
                  {
                        let url = API_URL_ADMIN + 'user-access-set';
                        response = await axios.post(url, data, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setCommonLoading", false);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setCommonLoading", false);
                        return Promise.resolve(response.data);
                  }
            },

            async TournamentInviteUsers({ commit }, data)
            {
                  let response;
                  commit("setCommonLoading", true);
                  try
                  {
                        let url = API_URL_ADMIN + 'tournament-invite';
                        response = await axios.post(url, data, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setCommonLoading", false);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setCommonLoading", false);
                        return Promise.resolve(response.data);
                  }
            },

            async SavePayment({ commit }, data)
            {
                  commit("setPaymentAddLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_ADMIN + 'payment-post';
                        response = await axios.post(url, data, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setPaymentAddLoading", false);
                        commit("setPaymentAddError", true);
                        commit("setPaymentAddErrors", error.errors);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setPaymentAdd", response.data.data);
                        commit("setPaymentAddLoading", false);
                        commit("setPaymentAddError", response.data.status != "ok");
                        commit("setPaymentAddErrors", response.data.errors);
                        return Promise.resolve(response.data);
                  }
            },

            async SaveUserPayment({ commit }, data)
            {
                  let response;
                  commit("setCommonLoading", true);
                  try
                  {
                        let url = API_URL_ADMIN + 'user-payment-post';
                        response = await axios.post(url, data, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setCommonLoading", false);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setCommonLoading", false);
                        return Promise.resolve(response.data);
                  }
            },

            async SaveWinners({ commit }, data)
            {
                  let response;
                  commit("setCommonLoading", true);
                  try
                  {
                        let url = API_URL_ADMIN + 'winners-template-post';
                        response = await axios.post(url, data, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setCommonLoading", false);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setCommonLoading", false);
                        return Promise.resolve(response.data);
                  }
            },

            async CalcStats({ commit }, data)
            {
                  let response;                  
                  try
                  {
                        let url = API_URL_ADMIN + 'calculate-statistics';
                        response = await axios.post(url, data, { headers: authHeader() });
                  }
                  catch (error)
                  {                        
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setCommonLoading", false);
                        return Promise.resolve(response.data);
                  }
            },
            
      },

      namespaced: true
};
