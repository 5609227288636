<template>
    <div id="app">        
        <router-view></router-view>        
    </div>
</template>

<script>
export default {
      name: 'App',    
      metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Carlberg-tipset!',
            meta:[{name: 'description', content: "Carlberg-tipset för dig som älskar tipsa sport och resultat!"}],
            // all titles will be injected into this template
            titleTemplate: '%s | Carlberg-tipset'
      }
};
</script>

<style lang="scss">
 @import "styles/main.scss";
</style>
