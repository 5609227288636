import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_GET = process.env.VUE_APP_API_URL_GET;

export default {
      state: {
            tournaments: {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null                  
            },
            tournament:
            {
                  error: false,
                  errorMessage: "",
                  loading: true,
                  data: null,
            },
            usertournaments:
            {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null                  
            },
            tournamentusers:
            {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null                  
            },
            tournamentstats:
            {
                  error: false,
                  errorMessage: "",
                  loading: false,
                  data: null                  
            }
      },

      getters: {
            tournamentsloading: state =>
            {
                  return state.tournaments.loading;
            },
            tournaments: state =>
            {
                  return state.tournaments.data;
            },           
            tournamentloading: state =>
            {
                  return state.tournament.loading;
            },
            tournament: state =>
            {
                  return state.tournament.data;
            },
            usertournamentsloading: state => {
                  return state.usertournaments.loading;
            },
            usertournaments: state => {
                  return state.usertournaments.data;
            },
            tournamentusersloading: state => {
                  return state.tournamentusers.loading;
            },
            tournamentusers: state => {
                  return state.tournamentusers.data;
            },
            tournamentstatsloading: state => {
                  return state.tournamentstats.loading;
            },
            tournamentstats: state => {
                  return state.tournamentstats.data;
            }
      },

      mutations: {
            setTournaments(state, data)
            {
                  state.tournaments.data = data;
            },
            setTournamentsLoading(state, status)
            {
                  state.tournaments.loading = status;
            },            
            setTournamentsError(state, status)
            {
                  state.tournaments.error = status;
            },
            setTournamentsErrorMessage(state, message)
            {
                  state.tournaments.errorMessage = message;
            },            
            setTournament(state, data)
            {
                  state.tournament.data = data;
            },          
            setTournamentLoading(state, status)
            {
                  state.tournament.loading = status;
            },
            setTournamentError(state, status)
            {
                  state.tournament.error = status;
            },
            setTournamentErrorMessage(state, message)
            {
                  state.tournament.errorMessage = message;
            },
            setUserTournaments(state, data)
            {
                  state.usertournaments.data = data;
            },          
            setUserTournamentsLoading(state, status)
            {
                  state.usertournaments.loading = status;
            },
            setUserTournamentsError(state, status)
            {
                  state.usertournaments.error = status;
            },
            setUserTournamentsErrorMessage(state, message)
            {
                  state.usertournaments.errorMessage = message;
            },
            setTournamentUsers(state, data)
            {
                  state.tournamentusers.data = data;
            },          
            setTournamentUsersLoading(state, status)
            {
                  state.tournamentusers.loading = status;
            },
            setTournamentUsersError(state, status)
            {
                  state.tournamentusers.error = status;
            },
            setTournamentUsersErrorMessage(state, message)
            {
                  state.tournamentusers.errorMessage = message;
            },
            setTournamentStats(state, data)
            {
                  state.tournamentstats.data = data;
            },          
            setTournamentStatsLoading(state, status)
            {
                  state.tournamentstats.loading = status;
            },
            setTournamentStatsError(state, status)
            {
                  state.tournamentstats.error = status;
            },
            setTournamentStatsErrorMessage(state, message)
            {
                  state.tournamentstats.errorMessage = message;
            }
      },

      actions: {
            async getTournaments({ commit })
            {
                  commit("setTournamentsLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'tournaments';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setTournamentsLoading", false);
                        commit("setTournamentsError", true);
                        commit("setTournamentsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setTournaments", response.data.data);                        
                        commit("setTournamentsLoading", false);
                        commit("setTournamentsError", false);
                        commit("setTournamentsErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }                  
            },         

            async getTournament({ commit })
            {
                  commit("setTournamentLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'tournament';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setTournamentLoading", false);
                        commit("setTournamentError", true);
                        commit("setTournamentErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setTournament", response.data.data);                        
                        commit("setTournamentLoading", false);
                        commit("setTournamentError", false);
                        commit("setTournamentErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }                  
            }, 

            async getTournamentUsers({ commit }) // deltagare
            {
                  commit("setTournamentUsersLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'tournament-users';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setTournamentUsersLoading", false);
                        commit("setTournamentUsersError", true);
                        commit("setTournamentUsersErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setTournamentUsers", response.data.data);                        
                        commit("setTournamentUsersLoading", false);
                        commit("setTournamentUsersError", false);
                        commit("setTournamentUsersErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }                  
            }, 

            async getUserTournaments({ commit })
            {
                  commit("setUserTournamentsLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'user-tournaments';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setUserTournamentsLoading", false);
                        commit("setUserTournamentsError", true);
                        commit("setUserTournamentsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setUserTournaments", response.data.data);                        
                        commit("setUserTournamentsLoading", false);
                        commit("setUserTournamentsError", false);
                        commit("setUserTournamentsErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  } 
            },

            async getTournamentStats({ commit })
            {
                  commit("setTournamentStatsLoading", true);

                  let response;
                  try
                  {
                        let url = API_URL_GET + 'tournament-stats';
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setTournamentStatsLoading", false);
                        commit("setTournamentStatsError", true);
                        commit("setTournamentStatsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setTournamentStats", response.data.data);
                        commit("setTournamentStatsLoading", false);
                        commit("setTournamentStatsError", false);
                        commit("setTournamentStatsErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  } 
            }
      },

      namespaced: true
};
