import axios from "axios";
import authHeader from './../../services/auth-header';

const API_URL_GET = process.env.VUE_APP_API_URL_GET;

export default {
      state: {
            stats: {
                  error: false,
                  errorMessage: "",
                  loading: true,
                  data: null
            },
            userstats:{
                  loading: true,                  
            }
      },

      getters: {
            loading: state =>
            {
                  return state.stats.loading;
            },
            stats: state =>
            {
                  return state.stats.data;
            }
      },

      mutations: {
            setEmail(state, email)
            {
                  state.stats.data.email = email;
            },
            setStats(state, data)
            {
                  state.stats.data = data;
            },
            setStatsLoading(state, status)
            {
                  state.stats.loading = status;
            },
            setStatsError(state, status)
            {
                  state.stats.error = status;
            },
            setStatsErrorMessage(state, message)
            {
                  state.stats.errorMessage = message;
            },
            setByKey(state, { key, value })
            {
                  state.stats.data[key] = value;
            },
            setUserStatsLoading(state, value){
                  state.userstats.loading = value;
            }
      },

      actions: {
            async getStats({ commit }, gamenr)
            {
                  commit("setStatsLoading", true);

                  let response;
                  try
                  {
                        var url = API_URL_GET + 'stats';
                        if (gamenr != "")
                              url += "?gamenr=" + gamenr;
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setStatsLoading", false);
                        commit("setStatsError", true);
                        commit("setStatsErrorMessage", error.message);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setStats", response.data.data);
                        commit("setStatsLoading", false);
                        commit("setStatsError", false);
                        commit("setStatsErrorMessage", "");
                        return Promise.resolve(response.data.data);
                  }
            },

            async getUserTournamentStats({commit}, {username, tournamentid})
            {
                  commit("setUserStatsLoading", true);
                  let response;
                  try
                  {
                        var url = API_URL_GET + 'user-stats?username=' + username + "&tournament=" + tournamentid;
                        response = await axios.get(url, { headers: authHeader() });
                  }
                  catch (error)
                  {
                        commit("setUserStatsLoading", false);
                        return Promise.reject(error);
                  }
                  if (response)
                  {
                        commit("setUserStatsLoading", false);
                        return Promise.resolve(response.data.data);
                  }
            }
      },

      namespaced: true
};
